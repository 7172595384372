import * as React from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import Layout from "../../../components/Layout";
import ServiceLayout from "../../../components/ServiceLayout";
import PayPerClickPage from "../../../components/pages/ServicePages/PayPerClickPage";
import Provider, {Store} from "../../../components/Store";

const IndexPage = (props) => {
    return (
        <Provider>
            <Store.Consumer>
                {
                    state => {
                        const defaultProps = {...props, ...state, ...state.login, ...state.style}
                        return (
                            <Layout {...defaultProps}>
                                <Header {...defaultProps}/>
                                <div className="app-content u-paddingTop65">
                                    <ServiceLayout {...defaultProps} title="Pay Per Click">
                                        <PayPerClickPage {...defaultProps}/>
                                    </ServiceLayout>
                                </div>
                                <Footer {...defaultProps}/>
                            </Layout>
                        )
                    }
                }
            </Store.Consumer>
        </Provider>
    )
}

export default IndexPage
