import React, {useState, useEffect} from 'react';
import "./style.styl";

export default function PayPerClickPage(props) {
    return (
        <div className="container draft-view">
           <h1>Pay Per Click (PPC)</h1>
           <p className="text-muted">Contextual advertising or PPC is a fast way to get traffic and customers on your site. Specialized ad platforms allow you to advertise products, services, games, and mobile applications on the web around the world or locally.</p>
           <p className="text-muted">If you want to use contextual advertising, our PPC specialists (mostly Google Ads and Facebook) help you advertise the smart way.</p>
           <p className="text-muted"></p>
           <h2>What PPC can do for your business</h2>
           <p className="text-muted">Web advertising has numerous advantages for your business.</p>
           <p className="text-muted">PPC allows you to advertise your product not only for potential customers but also for people who simply take an interest in a product or service. You can attract more new customers to your brand.</p>
           <p className="text-muted">Contextual ad platforms allow you to target an audience by key requests or interests. You can also set up preferred locations, languages, and interests, as well as specify a time period to attract relevant traffic. You can also, of course, set up an ad to attract as wide of an audience as possible. You can use different types of ads such as texts, banners, and videos. It’s better to use contextual advertising in cases when you need to increase traffic within a specific period of time, such as right after opening your website, after price updates, for special offers, or for special periods as Black Friday or Christmas.</p>
           <p className="text-muted">Some types of ads like Google Shopping Campaign allow you to use contextual ads over a long period. This is why you should identify your business goals and entrust your project to our PPC specialists.</p>
           <p className="text-muted"></p>
           <h2>Advantages of PPC</h2>
           <p className="text-muted"></p>
           <h5>1. Remarketing</h5>
           <p className="text-muted">Remarketing is a process of reaching people who have previously visited your website. Remarketing requires special data gathered by analytics tools such as Google Analytics or Facebook Pixel.</p>
           <p className="text-muted"></p>
           <h5>2. Measurable Results</h5>
           <p className="text-muted">PPC is a way to see measurable results in comparison with other site promotion methods. Contextual advertising platforms provide different forms of metrics that enable you to see how well ads are converting visitors into customers.</p>
           <p className="text-muted"></p>
           <h5>3. Greater control</h5>
           <p className="text-muted">Total control is impossible because all PPC platforms are based on algorithms and statistical models. Although contextual advertising can be an expensive method, it leaves you less susceptible to many factors in comparison with SEO, content marketing, or social promotion. You can determine which keywords, ad messages, and landing pages are converting and how much you should pay for them. This control will help you manage ads to maximize your results.</p>
           <p className="text-muted"></p>
           <h2 >Your benefits</h2>
           <p className="text-muted"></p>
           <h5>1. Your revenue</h5>
           <p className="text-muted">Contextual ads are all about sales. We use several methods to achieve a maximal profit for you. We check your site and its</p>
           <p className="text-muted"><br /></p>
           <h5>2. Get more conversions</h5>
           <p className="text-muted">It’s not enough just to attract visitors to your site. A user-friendly site converts visitors into buyers. That’s why we analyze your site’s</p>
           <p className="text-muted"><br /></p>
           <h5>3. Clear marketing data</h5>
           <p className="text-muted">Contextual ad platforms and analytics tools allow you to collect data about your site and audience. We process that data and</p>
           <p className="text-muted"><br /></p>
           <h2>Additional info about PPC</h2><p  className="text-muted">Contextual advertising always carries the risk of losing money. Our recommendation is that you have realistic expectations from a PPC. The truth is that PPC marketing has both advantages and disadvantages.</p><p  className="text-muted">You should understand that contextual advertising platforms need time to gather data, which is a required part of your budget.</p><p  className="text-muted">We make realistic assessments and we are always ready to endorse you.</p>

        </div>
    );
}
